<template>
  <div>
    <v-menu
      transition="slide-y-transition"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-2"
          rounded
          outlined
          dark
          v-on="on"
          v-bind="attrs"
        >
          Atualizar status
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          @click="updateMultipleStatus(item.value)"
          v-for="(item, i) in statusItems"
          :key="i"
          link
        >
          <v-list-item-title>
            {{ item.text }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn
      class="ml-2 white primary--text"
      rounded
      @click="deleteMultiple"
    >
      Excluir
      <v-icon right>fas fa-trash</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    selectedItems: Array,
  },
  data: () => ({
    statusItems: [
      { text: 'Ativar', value: true },
      { text: 'Desativar', value: false },
    ],
  }),
  methods: {
    updateMultipleStatus(value) {
      this.updateStatus(value)
    },
  },
  updateStatus(value) {
    let selectedIds = this.selectedItems.map((item) => {
      return item.id
    })
    this.$api
      .post(`people/status/mass`, {
        selectedIds: selectedIds,
        value: value,
      })
      .then(() => {
        this.$store.commit('setModal', {
          show: true,
          component: 'success',
          text: 'Status alterado com sucesso',
          confirm: () => {
            this.$store.commit('setModal', {
              show: false,
            })
            this.$emit('modalClicked')
          },
        })
      })
      .catch(() => {
        this.$store.commit('setModal', {
          show: true,
          component: 'error',
          text: 'Ops! Falha ao tentar alterar status',
          confirm: () => {
            this.$store.commit('setModal', {
              show: false,
            })
          },
        })
      })
  },
  deleteMultiple() {
    this.$store.commit('setModal', {
      show: true,
      component: 'warning',
      text: 'Atenção!',
      caption: `Deseja mesmo deletar ${this.selectedItems.length} agências?`,
      confirm: () => {
        let selectedIds = this.selectedItems.map((item) => {
          return item.id
        })
        this.$api
          .post(`people/massdelete`, selectedIds)
          .then(() => {
            this.$store.commit('setModal', {
              show: true,
              component: 'success',
              text: 'Agências excluidos com sucesso',
              confirm: () => {
                this.$store.commit('setModal', {
                  show: false,
                })
                this.$emit('modalClicked')
              },
            })
          })
          .catch(() => {
            this.$store.commit('setModal', {
              show: true,
              component: 'error',
              text: 'Ops! Falha ao tentar excluir estes Agências',
              confirm: () => {
                this.$store.commit('setModal', {
                  show: false,
                })
              },
            })
          })
      },
      negate: () => {
        this.$store.commit('setModal', {
          show: false,
        })
      },
    })
  },
}
</script>